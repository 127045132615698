import React from 'react';

function _getAttributesWithPrefix<Props extends object>(
  prefix: string,
  props: Props,
): Record<string, unknown> {
  const result: Record<string, unknown> = {};
  for (const [k, v] of Object.entries(props)) {
    if (k.startsWith(prefix)) {
      result[k] = v;
    }
  }
  return result;
}

export function getDataAttributes<
  Props extends Record<`data-${string}`, unknown>,
>(props: Props) {
  return _getAttributesWithPrefix('data-', props);
}
export function getAriaAttributes<Props extends React.AriaAttributes>(
  props: Props,
) {
  return _getAttributesWithPrefix('aria-', props);
}
