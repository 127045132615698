import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getWindow } from '@Lib/global';
import FormattedMessage, {
  formatMessage,
  FormattedHTMLMessage,
} from '@Lib/i18n';
import { chatToCustomerSupport } from '@Lib/intercom';
import Blob from '@UI/blob';
import Button from '@UI/button';

import config from '../../universal/config';
import LoginLayout from '../login-layout';

import styles from './sso-login-page-styles.scss';

const handleChatToSupport = (ev) => {
  ev.preventDefault();
  chatToCustomerSupport(formatMessage('login.sso.customerSupportMessage'));
};

const ERROR_EMPTY = 'email_empty';
const ERROR_UNAUTHORIZED = 'ErrUnauthorized'; // Comes through url params from backend
const ERROR_DEFAULT = 'default';

const ERROR_MESSAGES = {
  [ERROR_EMPTY]: <FormattedMessage id="login.sso.error.emailEmpty" />,
  [ERROR_UNAUTHORIZED]: (
    <div>
      <FormattedMessage id="login.sso.error.unauthorized" />{' '}
      <a href="#" onClick={handleChatToSupport}>
        <FormattedMessage id="login.sso.error.contactSupport" />
      </a>{' '}
      <FormattedMessage id="login.sso.error.enableSso" />
    </div>
  ),
  [ERROR_DEFAULT]: (
    <FormattedHTMLMessage
      id="login.sso.error.default"
      values={{
        a: (chunk) => <a href="mailto:support@geckoboard.com">{chunk}</a>,
      }}
    />
  ),
};

const SSOLoginPage = () => {
  const submitUrl = `${config.get().AutographBaseURL}/sso/login`;
  const [error, setError] = useState(null);
  const [defaultEmail, setDefaultEmail] = useState(null);

  useEffect(() => {
    const queryString = getWindow().location.search;
    const urlParams = new URLSearchParams(queryString);

    const urlError = urlParams.get('error');
    const displayedError = ERROR_MESSAGES[urlError] ? urlError : ERROR_DEFAULT;

    if (urlError) {
      setError(displayedError);
    }

    const urlEncodedEmail = urlParams.get('email');
    if (urlEncodedEmail) {
      setDefaultEmail(decodeURI(urlEncodedEmail));
    }
  }, []);

  const onValidateForm = (e) => {
    const email = e.target.elements.email.value;
    setError(null);

    if (email === '') {
      setError(ERROR_EMPTY);
      e.preventDefault();
      return false;
    }

    return true;
  };

  return (
    <LoginLayout
      title="Sign in to Geckoboard with SSO"
      heading="Sign in with SSO"
      footer={
        <p style={{ marginTop: '4rem' }}>
          Here by mistake? <Link to="/login">Sign in without SSO</Link>
        </p>
      }
    >
      {error && (
        <div data-testid="error" className={styles.errorMessage}>
          {ERROR_MESSAGES[error]}
        </div>
      )}
      <form method="post" action={submitUrl} onSubmit={onValidateForm}>
        <div className={styles.formField}>
          <input
            type="email"
            placeholder="Email address"
            aria-label="Email address"
            name="email"
            {...(defaultEmail && {
              defaultValue: defaultEmail,
            })}
            className={styles.emailInput}
            autoFocus
          />
          <div className={styles.blob}>
            <Blob version={Blob.VERSIONS.FIRST} />
          </div>
        </div>
        <Button stretched type={Button.TYPES.SUBMIT}>
          <FormattedMessage id="login.sso.submitButton" />
        </Button>
      </form>
    </LoginLayout>
  );
};

export default SSOLoginPage;
