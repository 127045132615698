import PropTypes from 'prop-types';
import React from 'react';

import styles from './blob.scss';

const assetBaseUrl = 'https://s3.amazonaws.com/gecko.static/images';

const BLOB_VERSIONS = {
  FIRST: 'blob_1.svg',
  SECOND: 'blob_2.svg',
  THIRD: 'blob_3.svg',
};

const Blob = ({ size, version, children }) => (
  <div
    className={styles.Blob}
    style={{
      width: `${size}px`,
      height: `${size}px`,
      backgroundImage: `url(${assetBaseUrl}/${version})`,
    }}
  >
    {children}
  </div>
);

Blob.VERSIONS = BLOB_VERSIONS;

Blob.defaultProps = {
  size: 50,
  version: BLOB_VERSIONS.FIRST,
};

Blob.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  version: PropTypes.oneOf([
    BLOB_VERSIONS.FIRST,
    BLOB_VERSIONS.SECOND,
    BLOB_VERSIONS.THIRD,
  ]),
};

export default Blob;
