import React from 'react';
import { Helmet } from 'react-helmet';

import Curve from './images/curve.svg';
import Logo from './images/logo.svg';

import styles from './login-layout.scss';

type LoginLayoutProps = {
  title: string;
  heading: string;
  subheading?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
};

export default function LoginLayout(props: LoginLayoutProps) {
  return (
    <div className={styles.page}>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <section className={styles.headerSection}>
        <div className={styles.curve}>
          <Curve />
        </div>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.heading}>
          <h2>{props.heading}</h2>
          {props.subheading && <p>{props.subheading}</p>}
        </div>
      </section>

      <section className={styles.formSection}>
        <div className={styles.formBox}>{props.children}</div>
      </section>

      {props.footer && (
        <section className={styles.footerSection}>{props.footer}</section>
      )}
    </div>
  );
}
